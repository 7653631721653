import React, { useMemo, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import cloneDeep from 'helpers/cloneDeep';
import Translation from 'components/data/Translation';
import Button from 'components/ui-components-v2/Button';
import Accordion from 'components/bricks/components/shared/components/accordion';
import CustomizedDialogs from 'components/ui-components/Dialog/components/dialog';
import { EditorLocalScope } from 'components/editor-data/EditorLocalScope';
import MultiInput from 'components/editor-blocks/MultiInput';
import EditorData from 'components/editor-data/EditorData';
import { generateKey } from 'components/template-designer/utils/generateKey';
import { Preset } from 'components/bricks/types/preset';
import Icon from 'components/ui-components-v2/Icon';
import { Brick, FileExt, FileType } from 'components/bricks/types/brick.type';
import { MODEL_PRESETS } from 'components/bricks/constants';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';
import { useBricksNavigation } from 'components/bricks/hooks/useBricksNavigation';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import { DEFAULT_CUSTOM_PRESET, fileTypeToExtensions } from '../data/custom-presets';
import customPresetDialogSettings from './custom-preset-dialog-inputs';
import PresetHelpers from '../helpers/presets.helper';
import '../styles/custom-presets-dialog.scss';

interface Props {
    open: boolean;
    preset?: Preset;
    defaultAssetType?: FileType;
    defaultFileExtension?: FileExt;
    onClose: () => void;
    onSave: (newPreset: Preset) => void;
}

const isRevalidationNeeded = (brick: Brick, presetToSave: Preset): boolean => {
    return brick?.data?.presets?.some((preset: Preset) => preset.identifier === presetToSave.identifier);
};

const CustomPresetDialog = ({ open, preset, defaultAssetType, defaultFileExtension, onClose, onSave }: Props) => {
    const [customPreset, setCustomPreset] = useState<Preset>(preset || DEFAULT_CUSTOM_PRESET);

    const localScopeUuid = useMemo(() => `i_${customPreset?.identifier || generateKey()}`, [customPreset.identifier]);
    const { activeItemId } = useBricksNavigation();
    const brick = BrickHelpers.getBrickById(activeItemId);

    const handleSaveCustomFormats = () => {
        const presetToSave = {
            ...customPreset,
            identifier: customPreset.identifier || generateKey()
        };

        onSave(presetToSave);

        if (brick && isRevalidationNeeded(brick, presetToSave)) {
            PresetHelpers.validateAssetByPresets(brick, [presetToSave]);
            BricksComponentStoreHelper.setBrickModel(brick.id, MODEL_PRESETS, [presetToSave]);
        }

        onClose();
    };

    const handleOnChange = (newCustomPresetData: Preset) => {
        setCustomPreset((prevPreset) => {
            const updatedData = cloneDeep(newCustomPresetData);

            // If assetType changes, reset restrictions and extra fields
            if (updatedData.assetType !== prevPreset.assetType) {
                updatedData.restrictions = DEFAULT_CUSTOM_PRESET.restrictions;
                updatedData.extraFields = DEFAULT_CUSTOM_PRESET.extraFields;
                delete updatedData.recommendations;

                // Get default extensions for new asset type
                const extensions = fileTypeToExtensions[updatedData.assetType];
                updatedData.restrictions.fileExtension = extensions?.length ? extensions[0] : '';
            }

            // Ensure fileExtension is stored as a string, not an array
            if (updatedData.restrictions.fileExtension && updatedData.fileExtension !== updatedData.restrictions.fileExtension) {
                updatedData.fileExtension = updatedData.restrictions.fileExtension; // Save as string
            }

            return updatedData;
        });
    };

    /**
     * Render the interface blocks based on the page structure
     */
    const renderBlocks = () => {
        const additionalVars = { customPreset };
        return (
            <>
                {customPresetDialogSettings.map((item, index) => {
                    if (!EditorData.validateCondition(item, undefined, undefined)) {
                        return <React.Fragment key={index} />;
                    } else {
                        return (
                            <Accordion key={index} title={item.title} square expanded={item.opened}>
                                <MultiInput key={'customPreset'} data={{ ...item, additionalVars }} localScope={localScopeUuid} />
                            </Accordion>
                        );
                    }
                })}
            </>
        );
    };

    const initialData = useMemo(() => {
        if (customPreset) {
            return customPreset;
        } else {
            // default values for new custom presets
            return {
                ...DEFAULT_CUSTOM_PRESET,
                assetType: defaultAssetType || 'image',
                fileExtension: [defaultFileExtension || 'jpg']
            };
        }
    }, [customPreset, defaultAssetType, defaultFileExtension]);

    return (
        <CustomizedDialogs
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            classes={{ paper: 'custom-preset-dialog' }}
            title={preset ? Translation.get('customPresetDialog.editCustomPreset', 'bricks') : Translation.get('customPresetDialog.addCustomPreset', 'bricks')}>
            <div className="custom-preset-dialog__block-wrapper">
                <EditorLocalScope onChange={handleOnChange} localScopeUuid={localScopeUuid} initialData={initialData} renderBlocks={renderBlocks} />
            </div>
            <DialogActions className="custom-preset-dialog__actions">
                <Button onClick={onClose} type="button" color="primary" size="small">
                    {Translation.get('actions.cancel', 'common')}
                </Button>
                <Button onClick={handleSaveCustomFormats} type="button" variant="contained" color="primary" size="medium">
                    {preset ? Translation.get('customPresetDialog.saveChanges', 'bricks') : Translation.get('customPresetDialog.addCustomPreset', 'bricks')}
                    <Icon className="custom-preset-dialog__add-icon">{preset ? 'save' : 'add_circle'}</Icon>
                </Button>
            </DialogActions>
        </CustomizedDialogs>
    );
};

export default CustomPresetDialog;

import { IEditorBlock } from 'components/bricks/types/editorBlock.type';
import { aspectRatioOptions, fileTypeExtraFields } from '../data/custom-presets';

// The asset type is an image
const assetTypeImage = "additionalVars.customPreset.assetType === 'image'";

// The asset type is an video
const assetTypeVideo = "additionalVars.customPreset.assetType === 'video'";

// The asset type is an audio
const assetTypeAudio = "additionalVars.customPreset.assetType === 'audio'";

// The asset type is an document
const assetTypeDocument = "additionalVars.customPreset.assetType === 'document'";

// The asset type is an designFile
const assetTypeDesignFile = "additionalVars.customPreset.assetType === 'designFile'";

// Access the extra fields property of the custom preset
const customPresetExtraFields = 'additionalVars.customPreset.extraFields?';

const customPresetDialogSettings: IEditorBlock[] = [
    {
        title: 'Title & Description',
        itemType: 'block',
        type: 'multiInput',
        opened: false,
        items: [
            {
                type: 'text',
                model: 'title',
                label: 'Title',
                fullWidth: true,
                useValueObject: false
            },
            {
                type: 'text',
                model: 'description',
                label: 'Description',
                fullWidth: true,
                multiline: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Media Settings',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'select',
                model: 'assetType',
                label: 'Category',
                fullWidth: true,
                options: [
                    { key: 'image', value: 'Image' },
                    { key: 'video', value: 'Video' },
                    { key: 'audio', value: 'Audio' },
                    { key: 'document', value: 'Document' },
                    { key: 'zip', value: 'Web Assets' },
                    { key: 'designFile', value: 'Design file' }
                ],
                useValueObject: false
            },
            {
                type: 'select',
                model: 'restrictions.fileExtension',
                label: 'File Format',
                fullWidth: true,
                condition: `${assetTypeImage}`,
                options: ['jpg', 'jpeg', 'png', 'gif'],
                useValueObject: false
            },
            {
                type: 'select',
                model: 'restrictions.fileExtension',
                label: 'File Format',
                fullWidth: true,
                condition: `${assetTypeVideo}`,
                options: ['mp4', 'avi', 'mov', 'mkv'],
                useValueObject: false
            },
            {
                type: 'select',
                model: 'restrictions.fileExtension',
                label: 'File Format',
                fullWidth: true,
                condition: `${assetTypeAudio}`,
                options: ['mp3', 'wav', 'flac'],
                useValueObject: false
            },
            {
                type: 'select',
                model: 'restrictions.fileExtension',
                label: 'File Format',
                fullWidth: true,
                condition: `${assetTypeDocument}`,
                options: ['pdf', 'docx', 'xlsx', 'pptx'],
                useValueObject: false
            },
            {
                type: 'select',
                model: 'restrictions.fileExtension',
                label: 'File Format',
                fullWidth: true,
                condition: `additionalVars.customPreset.assetType === 'zip'`,
                options: ['zip'],
                useValueObject: false
            },
            {
                type: 'select',
                model: 'restrictions.fileExtension',
                label: 'File Format',
                fullWidth: true,
                condition: `${assetTypeDesignFile}`,
                options: {
                    psd: 'Photoshop (PSD)',
                    ai: 'Illustrator (AI)',
                    indd: 'Indesign (INDD)',
                    sketch: 'Sketch',
                    fig: 'Figma (FIG)',
                    xd: 'Adobe XD (XD)'
                },
                useValueObject: false
            },
            //Options for the extra fields for each asset type
            {
                type: 'autocomplete',
                model: 'extraFields.image',
                label: 'Restrictions',
                fullWidth: true,
                multiple: true,
                condition: `${assetTypeImage}`,
                options: fileTypeExtraFields.image,
                useValueObject: false
            },
            {
                type: 'autocomplete',
                model: 'extraFields.video',
                label: 'Restrictions',
                fullWidth: true,
                multiple: true,
                condition: `${assetTypeVideo}`,
                options: fileTypeExtraFields.video,
                useValueObject: false
            },
            {
                type: 'autocomplete',
                model: 'extraFields.audio',
                label: 'Restrictions',
                fullWidth: true,
                multiple: true,
                condition: `${assetTypeAudio}`,
                options: fileTypeExtraFields.audio,
                useValueObject: false
            },
            {
                type: 'autocomplete',
                model: 'extraFields.document',
                label: 'Restrictions',
                fullWidth: true,
                multiple: true,
                condition: `${assetTypeDocument}`,
                options: fileTypeExtraFields.document,
                useValueObject: false
            },
            {
                type: 'autocomplete',
                model: 'extraFields.designFile',
                label: 'Restrictions',
                fullWidth: true,
                multiple: true,
                condition: `${assetTypeDesignFile}`,
                options: fileTypeExtraFields.designFile,
                useValueObject: false
            },
            // Width for image, video and design file
            {
                type: 'number',
                model: 'restrictions.width',
                label: 'Width',
                maxLength: 4,
                fullWidth: true,
                useValueObject: false,
                condition: `(${assetTypeImage} || ${assetTypeVideo} || ${assetTypeDesignFile}) &&
                            ((${customPresetExtraFields}.image?.includes('width')) ||
                             (${customPresetExtraFields}.video?.includes('width')) ||
                             (${customPresetExtraFields}.designFile?.includes('width')))`,
                itemType: 'input'
            },
            // Height for image, video and design file
            {
                type: 'number',
                model: 'restrictions.height',
                label: 'Height',
                fullWidth: true,
                maxLength: 4,
                useValueObject: false,
                condition: `(${assetTypeImage} || ${assetTypeVideo} || ${assetTypeDesignFile}) &&
                            ((${customPresetExtraFields}.image?.includes('height')) ||
                             (${customPresetExtraFields}.video?.includes('height')) ||
                             (${customPresetExtraFields}.designFile?.includes('height')))`,
                itemType: 'input'
            },
            // Color mode for image and design file
            {
                type: 'select',
                model: 'recommendations.colorMode',
                label: 'Color Mode',
                fullWidth: true,
                options: ['RGB', 'CMYK'],
                condition: `(${assetTypeImage} || ${assetTypeDesignFile}) &&
                            ((${customPresetExtraFields}.image?.includes('colorMode')) ||
                            (${customPresetExtraFields}.designFile?.includes('colorMode')))`,
                useValueObject: false,
                multiple: false
            },
            // Aspect ratio for image and video
            {
                type: 'select',
                model: 'recommendations.aspectRatio',
                label: 'Aspect Ratio',
                fullWidth: true,
                options: aspectRatioOptions,
                condition: `(${assetTypeImage} || ${assetTypeVideo}) &&
                            ((${customPresetExtraFields}.image?.includes('aspectRatio')) ||
                            (${customPresetExtraFields}.video?.includes('aspectRatio')))`,
                useValueObject: false,
                multiple: false
            },
            {
                type: 'text',
                model: 'recommendations.customAspectRatio',
                label: 'Enter Custom Aspect Ratio',
                fullWidth: true,
                condition: `(${assetTypeImage} || ${assetTypeVideo}) &&
                            ((${customPresetExtraFields}.image?.includes('aspectRatio')) ||
                            (${customPresetExtraFields}.video?.includes('aspectRatio'))) && additionalVars.customPreset.recommendations?.aspectRatio === 'other'`,
                useValueObject: false
            },
            // Min width for image, video and design file
            {
                type: 'number',
                model: 'restrictions.minWidth',
                label: 'Minimum Width',
                fullWidth: true,
                condition: `(${assetTypeImage} || ${assetTypeVideo} || ${assetTypeDesignFile}) &&
                ((${customPresetExtraFields}.image?.includes('minWidth')) ||
                (${customPresetExtraFields}.video?.includes('minWidth')) ||
                (${customPresetExtraFields}.designFile?.includes('minWidth')))`,
                useValueObject: false
            },
            // Max width for image, video and design file
            {
                type: 'number',
                model: 'restrictions.maxWidth',
                label: 'Maximum Width',
                fullWidth: true,
                condition: `(${assetTypeImage} || ${assetTypeVideo} || ${assetTypeDesignFile}) &&
                            ((${customPresetExtraFields}.image?.includes('maxWidth')) ||
                             (${customPresetExtraFields}.video?.includes('maxWidth')) ||
                             (${customPresetExtraFields}.designFile?.includes('maxWidth')))`,
                useValueObject: false
            },
            // Min height for image, video and design file
            {
                type: 'number',
                model: 'restrictions.minHeight',
                label: 'Minimum Height',
                fullWidth: true,
                condition: `(${assetTypeImage} || ${assetTypeVideo} || ${assetTypeDesignFile}) &&
                            ((${customPresetExtraFields}.image?.includes('minHeight')) ||
                             (${customPresetExtraFields}.video?.includes('minHeight')) ||
                              (${customPresetExtraFields}.designFile?.includes('minHeight')))`,
                useValueObject: false
            },
            // Max height for image, video and design file
            {
                type: 'number',
                model: 'restrictions.maxHeight',
                label: 'Maximum Height',
                fullWidth: true,
                condition: `(${assetTypeImage} || ${assetTypeVideo} || ${assetTypeDesignFile}) &&
                            ((${customPresetExtraFields}.image?.includes('maxHeight')) ||
                             (${customPresetExtraFields}.video?.includes('maxHeight')) ||
                             (${customPresetExtraFields}.designFile?.includes('maxHeight')))`,
                useValueObject: false
            },
            // Max size in kb for image, video, audio, document and design file
            {
                type: 'number',
                model: 'restrictions.maxSizeKb',
                label: 'Maximum File Size (KB)',
                fullWidth: true,
                condition: `(${assetTypeImage} || ${assetTypeVideo} || ${assetTypeAudio} || ${assetTypeDocument} || ${assetTypeDesignFile}) &&
                            ((${customPresetExtraFields}.image?.includes('maxSizeKb')) ||
                             (${customPresetExtraFields}.video?.includes('maxSizeKb')) ||
                             (${customPresetExtraFields}.audio?.includes('maxSizeKb')) ||
                             (${customPresetExtraFields}.document?.includes('maxSizeKb')) ||
                             (${customPresetExtraFields}.designFile?.includes('maxSizeKb')))`,
                useValueObject: false
            },
            // Codec for video
            {
                type: 'text',
                model: 'recommendations.codec',
                label: 'Codec',
                fullWidth: true,
                condition: `${assetTypeVideo} && ${customPresetExtraFields}.video.some(field => field === 'codec')`,
                useValueObject: false
            },
            // Frame rate for video
            {
                type: 'number',
                model: 'recommendations.frameRate',
                label: 'Frame Rate (fps)',
                fullWidth: true,
                condition: `${assetTypeVideo} && ${customPresetExtraFields}.video.some(field => field === 'frameRate')`,
                useValueObject: false
            },
            // Bitrate for video and audio
            {
                type: 'number',
                model: 'recommendations.bitRate',
                label: 'Bitrate (kbps)',
                fullWidth: true,
                condition: `(${assetTypeVideo} || ${assetTypeAudio}) &&
                ((${customPresetExtraFields}.video?.includes('bitRate')) ||
                 (${customPresetExtraFields}.audio?.includes('bitRate')))`,
                useValueObject: false
            },
            // Min duration for video
            {
                type: 'number',
                model: 'recommendations.minDuration',
                label: 'Minimum Duration (seconds)',
                fullWidth: true,
                condition: `${assetTypeVideo} && ${customPresetExtraFields}.video.some(field => field === 'minDuration')`,
                useValueObject: false
            },
            // Max duration for video
            {
                type: 'number',
                model: 'recommendations.maxDuration',
                label: 'Maximum Duration (seconds)',
                fullWidth: true,
                condition: `${assetTypeVideo} && ${customPresetExtraFields}.video.some(field => field === 'maxDuration')`,
                useValueObject: false
            },
            // Sample rate for audio
            {
                type: 'number',
                model: 'recommendations.sampleRate',
                label: 'Sample Rate (kHz)',
                fullWidth: true,
                condition: `${assetTypeAudio} && ${customPresetExtraFields}.audio.some(field => field === 'sampleRate')`,
                useValueObject: false
            },
            // Channels for audio
            {
                type: 'select',
                model: 'recommendations.channelLayout',
                label: 'Channels',
                fullWidth: true,
                options: ['Mono', 'Stereo', '5.1 Surround'],
                condition: `${assetTypeAudio} && ${customPresetExtraFields}.audio.some(field => field === 'channels')`,
                useValueObject: false
            },
            // Number of pages for document
            {
                type: 'number',
                model: 'recommendations.numberOfPages',
                label: 'Number of Pages',
                fullWidth: true,
                condition: `${assetTypeDocument} && ${customPresetExtraFields}.document.some(field => field === 'numberOfPages')`,
                useValueObject: false
            },
            // Page width for document
            {
                type: 'number',
                model: 'recommendations.pageWidth',
                label: 'Page Width (mm)',
                fullWidth: true,
                condition: `${assetTypeDocument} && ${customPresetExtraFields}.document.some(field => field === 'pageWidth')`,
                useValueObject: false
            },
            // Page height for document
            {
                type: 'number',
                model: 'recommendations.pageHeight',
                label: 'Page Height (mm)',
                fullWidth: true,
                condition: `${assetTypeDocument} && ${customPresetExtraFields}.document.some(field => field === 'pageHeight')`,
                useValueObject: false
            }
        ]
    }
];

export default customPresetDialogSettings;

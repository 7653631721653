import { Preset } from 'components/bricks/types/preset';

export const fileTypeExtraFields = {
    image: {
        colorMode: 'Color Mode',
        aspectRatio: 'Aspect Ratio',
        maxSizeKb: 'Maximum File Size (KB)',
        height: 'Height',
        width: 'Width',
        minWidth: 'Minimum Width',
        maxWidth: 'Maximum Width',
        minHeight: 'Minimum Height',
        maxHeight: 'Maximum Height'
    },
    video: {
        maxSizeKb: 'Maximum File Size (KB)',
        height: 'Height',
        width: 'Width',
        aspectRatio: 'Aspect Ratio',
        codec: 'Codec',
        bitRate: 'Bitrate',
        minWidth: 'Minimum Width',
        maxWidth: 'Maximum Width',
        minHeight: 'Minimum Height',
        maxHeight: 'Maximum Height',
        minDuration: 'Minimum Duration',
        maxDuration: 'Maximum Duration',
        frameRate: 'Frame Rate'
    },
    audio: {
        maxSizeKb: 'Maximum File Size (KB)',
        bitRate: 'Bitrate',
        sampleRate: 'Sample Rate'
    },
    document: {
        maxSizeKb: 'Maximum File Size (KB)',
        numberOfPages: 'Number of Pages',
        pageWidth: 'Page Width',
        pageHeight: 'Page Height'
    },
    designFile: {
        maxSizeKb: 'Maximum File Size (KB)',
        height: 'Height',
        width: 'Width',
        colorMode: 'Color Mode',
        minWidth: 'Minimum Width',
        maxWidth: 'Maximum Width',
        minHeight: 'Minimum Height',
        maxHeight: 'Maximum Height'
    }
};

export const fileTypeToExtensions = {
    image: ['jpg', 'jpeg', 'png', 'gif'],
    video: ['mp4', 'avi', 'mov', 'mkv'],
    audio: ['mp3', 'wav', 'flac'],
    document: ['pdf', 'docx', 'xlsx', 'pptx'],
    zip: ['zip'],
    designFile: ['psd', 'ai', 'indd', 'sketch', 'fig', 'xd']
};

export const aspectRatioOptions = [
    { key: '1:1', value: '1:1' },
    { key: '16:9', value: '16:9' },
    { key: '9:16', value: '9:16' },
    { key: '4:3', value: '4:3' },
    { key: '3:2', value: '3:2' },
    { key: '1.85:1', value: '1.85:1' },
    { key: '5:4', value: '5:4' },
    { key: 'other', value: 'Other' }
];

export const DEFAULT_CUSTOM_PRESET: Preset = {
    title: '',
    identifier: '',
    description: '',
    searchIndex: '',
    channel: 'all',
    platform: undefined,
    assetType: 'image',
    fileExtension: 'jpg',
    restrictions: { fileExtension: 'jpg' },
    extraFields: [],
    custom: true
};
